@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes colors {
  0% {
    border-color: #ff3e00;
  }
  25% {
    border-color: #ffdd00;
  }
  50% {
    border-color: #00ff00;
  }
  75% {
    border-color: #00dfff;
  }
  100% {
    border-color: #ff3e00;
  }
}

.spinner {
  border: 4px solid transparent;
  border-top-color: #ff3e00;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.9s linear infinite, colors 0.9s linear infinite;
}

/* index.css */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* index.css */
.register-animation-loader,
.register-animation-loader:before,
.register-animation-loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: register-animation-load7 1.8s infinite ease-in-out;
  animation: register-animation-load7 1.8s infinite ease-in-out;
}
.register-animation-loader {
  color: #ffffff;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.register-animation-loader:before,
.register-animation-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.register-animation-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.register-animation-loader:after {
  left: 1.5em;
}
@-webkit-keyframes register-animation-load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes register-animation-load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* index.css */
.login-animation-loader,
.login-animation-loader:before,
.login-animation-loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: login-animation-load7 1.8s infinite ease-in-out;
  animation: login-animation-load7 1.8s infinite ease-in-out;
}
.login-animation-loader {
  color: #ffffff;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.login-animation-loader:before,
.login-animation-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.login-animation-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.login-animation-loader:after {
  left: 1.5em;
}
@-webkit-keyframes login-animation-load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes login-animation-load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* General paragraph design */
p {
  font-size: 16px;
  line-height: 1.6;
  color: #4a4a4a;
  margin-bottom: 1.5em;
  font-family: 'Roboto', sans-serif;
}

/* Header 1 design */
h1 {
  font-size: 36px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 0.75em;
  font-family: 'Roboto', sans-serif;
}

/* Header 2 design */
h2 {
  font-size: 30px;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 0.75em;
  font-family: 'Roboto', sans-serif;
}

/* Header 3 design */
h3 {
  font-size: 24px;
  font-weight: 700;
  color: #3a3a3a;
  margin-bottom: 0.75em;
  font-family: 'Roboto', sans-serif;
}

/* Header 4 design */
h4 {
  font-size: 20px;
  font-weight: 700;
  color: #4a4a4a;
  margin-bottom: 0.75em;
  font-family: 'Roboto', sans-serif;
}

/* Header 5 design */
h5 {
  font-size: 18px;
  font-weight: 700;
  color: #5a5a5a;
  margin-bottom: 0.75em;
  font-family: 'Roboto', sans-serif;
}

/* Unordered list design */
ul {
  margin: 0 0 1.5em 1.5em;
  padding: 0;
  list-style-type: disc;
  color: #4a4a4a;
  font-family: 'Roboto', sans-serif;
}

/* Ordered list design */
ol {
  margin: 0 0 1.5em 1.5em;
  padding: 0;
  list-style-type: decimal;
  color: #4a4a4a;
  font-family: 'Roboto', sans-serif;
}

/* List item design */
li {
  margin-bottom: 0.5em;
  line-height: 1.6;
  font-size: 16px;
  color: #4a4a4a;
}

/* Code tag design */
code {
  /* background-color: #f5f5f5; */
  /* border: 1px solid #e1e1e1; */
  border-radius: 4px;
  padding: 2px 4px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  font-weight: 600;
  color: #0e4e6c;
}

@media (min-width: 1024px) {
  .lg\:hidden {
    display: none;
  }
}

@media (max-width: 1023px) {
  .lg\:relative {
    position: absolute;
  }
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

pre {
  white-space: pre-wrap
}