@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

/* Golden color styles */
.text-golden {
  color: #DAA520; /* Golden color */
}

.bg-golden {
  background-color: #DAA520; /* Golden background color */
}

.hover\:bg-golden-darker:hover {
  background-color: #C18E0E; /* Darker shade of golden */
}

/* Tailwind yellow color styles */
.text-yellow-400 {
  color: #FBBF24; /* Tailwind yellow-400 */
}

.bg-yellow-400 {
  background-color: #FBBF24; /* Tailwind yellow-400 */
}

.hover\:bg-yellow-500:hover {
  background-color: #F59E0B; /* Tailwind yellow-500 */
}

@media (max-width: 768px) {
  .sm\:text-3xl {
    font-size: 1.875rem; /* 30px */
  }
}

header {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  box-shadow: none; /* Remove default shadow */
  padding: 1rem 2rem; /* Add padding for spacing */
}

/* Animation for premium look */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-in-out;
}

/* Add styles for scrolled state */
.app-bar.scrolled {
  background-color: rgba(0, 0, 0, 0.7) !important;
  transition: background-color 0.3s ease;
}

/* Header link styling */
.header-link {
  color: white;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header-link:hover {
  color: #ffeb3b; /* Yellow color for hover */
}

/* Drawer link styling */
.drawer-link {
  background-color: #FBBF24; /* Tailwind yellow-400 */
  color: white;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.drawer-link:hover {
  background-color: #F59E0B; /* Darker yellow for hover */
  color: #ffeb3b; /* Yellow color for hover */
}

/* Unlock Access button styling */
.unlock-access {
  background-color: #FBBF24; /* Tailwind yellow-400 */
  color: #1a202c; /* Tailwind gray-900 */
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.unlock-access:hover {
  background-color: #F59E0B; /* Tailwind yellow-500 */
}

.drawer-link {
  background-color: rgba(255, 255, 255, 0.1);
}

.drawer-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}